import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";

const androidLink = process.env.GATSBY_ANDROID_LINK;
const iphoneLink = process.env.GATSBY_IPHONE_LINK;

console.log("links:", androidLink, iphoneLink);

export default ({ submitPhone, learnMore }) => {
  const [phoneType, setPhoneType] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneValid, setPhoneValid] = useState(false);
  const [textSent, setTextSent] = useState(false);
  const [isWaiting, setWaiting] = useState(false);
  const ayt = new AsYouType("US");

  // validate phone number
  useEffect(() => {
    try {
      console.log("phone:", phone);
      const phoneNumber = parsePhoneNumber(phone, "US");
      setPhoneValid(phoneNumber.isValid());
    } catch (e) {
      setPhoneValid(false);
    }
  }, [phone]);

  const setFormattedPhone = (phone) => {
    // formats phone number to US-centric (xxx) xxx-xxxx
    console.log("setting phone:", phone);
    // only do for over length 4 -- otherwise, when you try to delete (XXX, it formats
    // incorrectly.
    if (phone.length > 4) {
      setPhone(ayt.input(phone));
    } else {
      setPhone(phone);
    }
  };

  const sendSms = async (phone, message) => {
    const url = `/.netlify/functions/sms?`;
    try {
      const response = await fetch(
        url +
        new URLSearchParams({
          phone: phone,
          message: message,
        }),
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setTextSent(true);
        setWaiting(false);
      }
    } catch (err) {
      setWaiting(false);
      console.log(err);
    }
  };

  const sendBetaText = () => {
    console.log("Sending beta text...");
    setWaiting(true);
    if (phoneType == "android") {
      const msg = `Get the Gigbox beta: ${androidLink}`;
      console.log("Sending message:", msg);
      sendSms(phone, msg);
    } else if (phoneType == "iphone") {
      const msg = `Get the Gigbox beta through testflight: ${iphoneLink}`;
      sendSms(phone, msg);
    }
  };

  console.log("phone type:", phoneType);

  let acceptingTesters = false

  return (
    <div className="flex flex-col w-full border-black border-4 shadow bg-white p-2">
      <h1 className="text-2xl font-bold p-2">
        Sign up for the <span className="text-green-500">beta</span>
      </h1>
      <input
        value={phone}
        onInput={(e) => {
          e.preventDefault();
          setPhone(e.target.value);
          setFormattedPhone(e.target.value);
        }}
        className="shadow-inner border-black border p-2 m-2"
        className={`shadow-inner border-black border border-2 p-2 m-2 ${phoneValid ? "input-success" : "input-error"
          }`}
        placeholder="Phone Number"
      ></input>
      <div className="flex flex-row p-2 justify-between">
        <button
          onClick={() => setPhoneType("iphone")}
          className={
            "flex flex-row justify-center border-2 border-black p-2 hover:underline " +
            (phoneType == "iphone"
              ? "bg-black text-white font-bold"
              : "bg-white text-black ")
          }
        >
          <h1 className="text-lg hover:underline">iPhone</h1>
        </button>
        <button
          onClick={() => setPhoneType("android")}
          className={
            "flex flex-row justify-center border-2 border-black p-2 hover:underline " +
            (phoneType == "android"
              ? "bg-black text-white font-bold"
              : "bg-white")
          }
        >
          <h1 className="text-lg hover:underline">Android</h1>
        </button>
      </div>

      {textSent && <h2 className="text-xl">Link Sent!</h2>}

      {!acceptingTesters ?
        <a disabled={true}
          className={`flex flex-row justify-center p-2 bg-gray-300`}
        >
          <h1 className="text-l font-bold text-white">No Longer Accepting Testers</h1>
        </a>
        :
        <a
          disabled={phoneType == ""}
          onClick={() => isWaiting ? null : sendBetaText()}
          className={`flex flex-row justify-center p-2 ${phoneType == "" ? "bg-gray-300" : "bg-black"
            }`}
        >
          {!isWaiting ? (
            <h1 className="text-xl font-bold text-white">Get it</h1>
          ) : (
            <h1 className="text-xl font-bold text-white">Sending...</h1>
          )}
        </a>
      }
      {learnMore && (
        <Link
          to='/posts/posts/gigbox-beta-on-hol/'
          // to="/app"
          className={`flex flex-row justify-center border border-black border-4 p-2 mt-2 bg-black text-white hover:bg-white hover:text-black hover:underline`}
        >
          <h1 className="text-xl font-bold">Learn More</h1>
        </Link>
      )}
    </div>
  );
};
